import CoBrandingBannerDesktop_ClassicComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/CoBrandingBannerDesktop/viewer/skinComps/Classic/Classic.skin';


const CoBrandingBannerDesktop_Classic = {
  component: CoBrandingBannerDesktop_ClassicComponent
};


export const components = {
  ['CoBrandingBannerDesktop_Classic']: CoBrandingBannerDesktop_Classic
};

